import http from "../../index";
import uri from "../../uri";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { createReducerThunk, initialState } from "../../../reducers/index";

type createDataset = [
  {
    name: string;
    bucket: string;
    path: string;
    organisation: number;
  }
];

type UploadImageType = {
  datasetId: number;
  files: { file_name: string; bs64: string }[];
};

const reduxActionName = "upload-new-dataset-list-organisation";
const actionCreateDataset = "create-new-dataset";

export async function fetchListOrganisationService() {
  const result = await http.get(uri.uploadNewDataset.listOrganisation);
  return result.data;
}
export async function createDatasetOrganisation(payload: createDataset) {
  const result = await http.post(uri.uploadNewDataset.createDataset, payload);
  return { data: result.data, status: true };
}

export const datasetListOrganisationThunk: any = createAsyncThunk(
  reduxActionName,
  fetchListOrganisationService
);
export const datasetListOrganisationSlice = createReducerThunk(
  reduxActionName,
  initialState,
  datasetListOrganisationThunk
);

// start create upload new dataset
export const createNewDatasetThunk: any = createAsyncThunk(
  actionCreateDataset,
  createDatasetOrganisation
);

export const createNewDatasetSlice = createReducerThunk(
  actionCreateDataset,
  initialState,
  createNewDatasetThunk
);
export const createNewDatasetSliceReset = createNewDatasetSlice.actions.reset;
// end create upload new dataset

// start upload image dataset
const actionUploadImage = "upload-image-dataset";
const actionConfirmUploadImage = "confirm-upload-image-dataset";
export const uploadImageApi = async (payload: UploadImageType) => {
  const result = await http.post(uri.dataset.upload.upload(payload.datasetId), {
    files: payload.files
  });
  return result.data;
};
export const confirmUploadImageApi = async (payload: { datasetId: number }) => {
  const result = await http.post(uri.dataset.upload.confirm(payload.datasetId));
  return result.data;
};
export const uploadImageDatasetThunk: any = createAsyncThunk(actionUploadImage, uploadImageApi);
export const uploadImageDatasetSlice = createReducerThunk(
  actionUploadImage,
  initialState,
  uploadImageDatasetThunk
);

export const confirmUploadImageThunk: any = createAsyncThunk(
  actionConfirmUploadImage,
  confirmUploadImageApi
);
export const confirmUploadImageSlice = createReducerThunk(
  actionConfirmUploadImage,
  initialState,
  uploadImageDatasetThunk
);
// end upload image dataset
