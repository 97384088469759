import {
  engineerDefectDeleteSlice,
  engineerDefectCreateSlice,
  engineerDefectsSlice,
  engineerDefectUpdateSlice,
  engineerDefectUpdateNENSlice,
  engineerDefectCreateNENSlice
} from "../api/services/engineer/defect";
import {
  engineerListSlice,
  datasetDefectsSlice,
  getReportByBuildingSlice,
  engineerSmrtReportSlice,
  engineerPFILocationListSlice
} from "../api/services/engineer/list";
import {
  engineerMissionInfoEditSlice,
  engineerMissionInfoSlice
} from "../api/services/engineer/mission-info";
import {
  engineerOptionsSlice,
  engineerIntensitySlice,
  engineerExtentSlice
} from "../api/services/engineer/options";
import {
  engineerCreateTagSlice,
  engineerDeleteTagSlice,
  engineerListTagSlice,
  engineerRelocateTagSlice
} from "../api/services/engineer/tag";

export default {
  engineerList: engineerListSlice.reducer,

  engineerListTags: engineerListTagSlice.reducer,
  engineerCreateTags: engineerCreateTagSlice.reducer,
  engineerDeleteTag: engineerDeleteTagSlice.reducer,
  engineersRelocateTag: engineerRelocateTagSlice.reducer,

  engineerDefects: engineerDefectsSlice.reducer,
  engineerDefectUpdate: engineerDefectUpdateSlice.reducer,
  engineerDefectCreate: engineerDefectCreateSlice.reducer,
  engineerDefectDelete: engineerDefectDeleteSlice.reducer,

  engineerDefectUpdateNEN: engineerDefectUpdateNENSlice.reducer,
  engineerDefectCreateNEN: engineerDefectCreateNENSlice.reducer,

  engineerOptions: engineerOptionsSlice.reducer,
  engineerIntensity: engineerIntensitySlice.reducer,
  engineerExtent: engineerExtentSlice.reducer,

  engineerDefectsByImageId: datasetDefectsSlice.reducer,
  engineerReportByBuildingNen2: getReportByBuildingSlice.reducer,

  engineerMissionInfo: engineerMissionInfoSlice.reducer,
  engineerMissionInfoUpdate: engineerMissionInfoEditSlice.reducer,

  engineerSmrtReport: engineerSmrtReportSlice.reducer,

  engineerPFILocationListSlice: engineerPFILocationListSlice.reducer
};
