
import http from "../../index";
import uri from "../../uri";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { createReducerThunk, initialState } from "../../../reducers/index";

const reduxActionNameStandardList = "dataset-standard-list";
async function standardListApi() {
  const result = await http.get(uri.dataset.standard.list);
  return result.data;
}
export const datasetStandardListThunk: any = createAsyncThunk(reduxActionNameStandardList, standardListApi);
export const datasetStandardListSlice = createReducerThunk(reduxActionNameStandardList, initialState, datasetStandardListThunk);

// // -------------------------------------------------------------

const reduxActionNameSectionCurrent = "dataset-standard-current";
async function sectionCurrentApi(payload: { datasetId: number }) {
  const result = await http.get(uri.dataset.standard.current(payload.datasetId));
  return result.data;
}
export const datasetStandardCurrentThunk: any = createAsyncThunk(reduxActionNameSectionCurrent, sectionCurrentApi);
export const datasetStandardCurrentSlice = createReducerThunk(reduxActionNameSectionCurrent, initialState, datasetStandardCurrentThunk);

// // -------------------------------------------------------------

const reduxActionNameSectionUpdate = "dataset-standard-update";
async function sectionUpdateApi(payload: { datasetId: number; data: any }) {
  const result = await http.patch(uri.dataset.standard.current(payload.datasetId), payload.data);
  return result.data;
}
export const datasetStandardUpdateThunk: any = createAsyncThunk(reduxActionNameSectionUpdate, sectionUpdateApi);
export const datasetStandardUpdateSlice = createReducerThunk(reduxActionNameSectionUpdate, initialState, datasetStandardUpdateThunk);