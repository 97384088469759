import {
  updateReportDefectSlice,
  getHistoriesSlice,
  validatedDefectSlice
} from "../api/services/report/defect";
import { imageByImageIdSlice } from "../api/services/report/image";

export default {
  imageByImageId: imageByImageIdSlice.reducer,
  updateReportDefect: updateReportDefectSlice.reducer,
  historiesByImageId: getHistoriesSlice.reducer,
  validatedDefect: validatedDefectSlice.reducer
};
