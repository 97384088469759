
import http from "../../index";
import uri from "../../uri";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { createReducerThunk, initialState } from "../../../reducers/index";

export type tagUpdateType = {
  imageId: any;
  reviewId: number;
  data: {
    defect_geom: number[][];
  };
}

let reduxActionName = "engineer-tags-list";

async function listTagsApi(payload: { imageId: number }) {
  const result = await http.get(uri.engineers.listTag(payload.imageId));
  return result.data;
}

export const engineerListTagThunk: any = createAsyncThunk(reduxActionName, listTagsApi);
export const engineerListTagSlice = createReducerThunk(reduxActionName, initialState, engineerListTagThunk);
export const engineerListTagReset = engineerListTagSlice.actions.reset;


// -------------------------

reduxActionName = "engineer-tags-create";

async function createTagsApi(payload: tagUpdateType) {
  const result = await http.post(uri.engineers.listTag(payload.imageId), payload.data);
  return result.data;
}

export const engineerCreateTagThunk: any = createAsyncThunk(reduxActionName, createTagsApi);
export const engineerCreateTagSlice = createReducerThunk(reduxActionName, initialState, engineerCreateTagThunk);
export const engineerCreateTagReset = engineerCreateTagSlice.actions.reset;

// -------------------------

reduxActionName = "engineer-tags-delete";

async function deleteTagsApi(payload: { reviewId: number; }) {
  const result = await http.delete(uri.engineers.deleteTag(payload.reviewId));
  return result.data;
}

export const engineerDeleteTagThunk: any = createAsyncThunk(reduxActionName, deleteTagsApi);
export const engineerDeleteTagSlice = createReducerThunk(reduxActionName, initialState, engineerDeleteTagThunk);
export const engineerDeleteTagReset = engineerDeleteTagSlice.actions.reset;

// -------------------------

reduxActionName = "engineer-tags-relocate";

async function relocateTagsApi(payload: tagUpdateType) {
  const result = await http.patch(
    uri.engineers.relocateTag(payload.imageId, payload.reviewId), {
      defect_geom: payload.data.defect_geom
    });
  return result.data;
}

export const engineerRelocateTagThunk: any = createAsyncThunk(reduxActionName, relocateTagsApi);
export const engineerRelocateTagSlice = createReducerThunk(reduxActionName, initialState, engineerRelocateTagThunk);
export const engineerRelocateTagReset = engineerRelocateTagSlice.actions.reset;