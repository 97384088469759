export default {
  authentication: {
    login: "/auth/login/",
    refresh: "/auth/refresh/",
    changePassword: "/auth/password/change/",
    resetPassword: "/auth/password/reset/",
    confirmResetPassword: "/auth/password/reset/confirm/"
  },
  detail: (id: string) => `/detail/${id}`,
  dataset: {
    list: "/datasets/",
    standard: {
      list: "/datasets/standard/",
      current: (datasetId: number) => `/datasets/standard/${datasetId}/`
    },
    section: {
      list: "/datasets/section/",
      update: (imageId: number, sectionId: number) => `/datasets/section/${imageId}/${sectionId}/`
    },
    configure: {
      list: (dataSetId: number) => `/datasets/config/${dataSetId}/`,
      listHDB: "/datasets/config/hdb/",
      update: (dataSetId: number) => `/datasets/config/update/${dataSetId}/`
    },
    report: {
      export: "/datasets/report/export",
      generatePdfByBuilding: (datasetId: number) =>
        `/reports/generate_pdf_by_building/${datasetId}/`,
      generateExcelByBuilding: (datasetId: number) =>
        `/reports/generate_excel_by_building/${datasetId}/`,
      setFloorImageCompleted: (imageId: number) => `/reports/set_floor_image_completed/${imageId}/`
    },
    upload: {
      upload: (dataSetId: number) => `/datasets/upload/image/${dataSetId}/`,
      confirm: (dataSetId: number) => `/datasets/upload/image/confirm/${dataSetId}/`
    }
  },
  engineers: {
    list: (imageId: number) => `/engineers/analyze/${imageId}/`,
    listTag: (imageId: number) => `/engineers/analyze/${imageId}/tags/`,
    deleteTag: (reviewId: number) => `/engineers/analyze/tags/${reviewId}/`,
    relocateTag: (imageId: any, reviewId: number) =>
      `/engineers/analyze/${imageId}/tags/${reviewId}/`,
    defects: (reviewId: number) => `/engineers/analyze/tags/${reviewId}/defects`,
    options: (imageId: number) => `/engineers/analyze/${imageId}/options`,
    extent: () => "/engineers/extent/",
    intensity: () => "/engineers/intensity/",
    "defect-update": (reviewSubId: number) => `/engineers/analyze/defects/${reviewSubId}/`,
    "defect-create": () => "/engineers/analyze/defects/",
    "defect-update-nen": (reviewSubId: number) => `/engineers/analyze/defects-nen/${reviewSubId}/`,
    "defect-create-nen": () => "/engineers/analyze/defects-nen/",
    defectsByImageId: (imageId: number) => `/engineers/report/${imageId}/defects`,
    smrtReport: (dataSetId: number) => `/engineers/report/dataset-defects/${dataSetId}/`,
    reportByBuilding: (dataSetId: number) => `/engineers/report/nen-by-building/${dataSetId}/`,
    "mission-info": (imageId: number) => `/engineers/analyze/mission_info/${imageId}/`,
    location_list: "/engineers/analyze/location/"
  },
  report: {
    imageByImageId: (imageId: number) => `/reports/get_drawed_floor_image_with_defects/${imageId}/`,
    updateDefect: "/engineers/report/defects/",
    histories: "/histories/",
    validateDefect: (reviewId: number, reviewSubId: number) =>
      `/reports/set_defect_complete/${reviewId}/${reviewSubId}/`,
    reportGenerateByFloor: (imageId: number) => `/reports/generate_pdf_2_by_floor/${imageId}/`,
    reportGenerateNen2ByBuilding: (datasetId: number) =>
      `/reports/generate_pdf_2_by_building/${datasetId}/`,
    reportGenerateByBuilding: (datasetId: number) =>
      `/reports/generate_pdf_by_building/${datasetId}/`,
    reportGenerateWord: (imageId: number) => `/reports/generate_word/${imageId}/`,
    reportGenerateWordByBuilding: (datasetId: number, imageId: number) =>
      `/reports/generate_word_by_building/${datasetId}/${imageId}/`,
    exportHDBPSI: (imageId: number) => `/reports/generate_hdb_psi_excel/${imageId}/`
  },
  uploadNewDataset: {
    listOrganisation: "/datasets/organisations",
    createDataset: "/datasets/"
  }
};
