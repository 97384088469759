import { datasetListSlice } from "../api/services/dataset/list";
import {
  datasetSectionListSlice,
  datasetSectionUpdateSlice
} from "../api/services/dataset/section";
import {
  datasetStandardCurrentSlice,
  datasetStandardListSlice,
  datasetStandardUpdateSlice
} from "../api/services/dataset/standard";
import {
  datasetConfigureHDBSlice,
  datasetConfigureListSlice,
  datasetConfigureUpdateSlice
} from "../api/services/dataset/configure";
import {
  datasetListOrganisationSlice,
  createNewDatasetSlice
} from "../api/services/dataset/uploadNewDataset";
import { floorImageCompletedSlice } from "../api/services/report/defect";

export default {
  listDataset: datasetListSlice.reducer,
  listDatasetSection: datasetSectionListSlice.reducer,
  updateDatasetSection: datasetSectionUpdateSlice.reducer,
  listDatasetStandard: datasetStandardListSlice.reducer,
  currentDatasetStandard: datasetStandardCurrentSlice.reducer,
  updateDatasetStandard: datasetStandardUpdateSlice.reducer,
  listDatasetConfigure: datasetConfigureListSlice.reducer,
  listDatasetConfigureHDB: datasetConfigureHDBSlice.reducer,
  updateDatasetConfigure: datasetConfigureUpdateSlice.reducer,
  uploadNewDatasetListOrganisation: datasetListOrganisationSlice.reducer,
  createNewDataset: createNewDatasetSlice.reducer,
  floorImageCompleted: floorImageCompletedSlice.reducer
};
