export const TITLE = {
  HOME_PAGE_TITLE: "Interior Inspector",
  UPLOAD_PAGE_TITLE: "Upload",
  DATASET_REPORT_PAGE_TITLE: "Dataset Report",
  CHANGE_PASSWORD_PAGE_TITLE: "Change Password",
  DATASET_CONFIGURE_PAGE_TITLE: "Configure Dataset",
  DATASET_ANALYZE_PAGE_TITLE: "Dataset Analyze",
  HDB_REPORT: "HDB Quality Control Report",
  NEN_REPORT: "NEN Quality Control Report",
  LOGIN_PAGE_TITLE: "Sign In",
  FORGOT_PASSWORD_PAGE: "Forgot Password",
  FOUR_ZERO_FOUR: "404 - Not Found"
};
