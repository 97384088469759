export default {
  DATETIME: "MM/D/YYYY, H:mm:ss A",
  HOME_PAGE_TITLE: "Interior Inspector",
  LOGIN_PAGE_TITLE: "Sign In",
  UPLOAD_PAGE_TITLE: "Upload",
  DATASET_REPORT_PAGE_TITLE: "Dataset Report",
  CHANGE_PASSWORD_PAGE_TITLE: "Change Password",
  DATASET_CONFIGURE_PAGE_TITLE: "Configure Dataset",
  DATASET_ANALYZE_PAGE_TITLE: "Analyze Dataset",
  HDB_PAGE_TITLE: "Interior Inspector",
  REPORT_BY_FLOOR: "Report By Floor",
  REPORT_BY_BUILDING: "Report By Building",
  NEN_REPORT_BY_BUILDING: "NEN Report By Building",
  FORGOT_PASSWORD_PAGE: "Forgot Password"
};