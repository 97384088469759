import axios from "axios";
import cookie from "react-cookies";
import { environments } from "../../contants";
import { getRefreshToken, clearCookies } from "../../utils";
import { refreshToken } from "./services/authentication";
import { paths } from "../../configs/routeConfig";
import { errorMessage } from "../../configs/errors";

const http = axios.create({
  timeout: 60000 * 60, // 60 minutes
  baseURL: process.env.REACT_APP_API_URL,
  headers: {
    "Content-Type": "application/json"
  }
});

const DEFAULT_MESSAGE_ERROR = "Oops! Something went wrong, please try again";

http.interceptors.request.use((config: any) => {
  const accessToken = cookie.load(environments.REACT_APP_COOKIE_NAME);
  config.headers = {
    ...config.headers,
    ...(accessToken ? { Authorization: `Bearer ${accessToken}` } : {})
  };

  return config;
});

http.interceptors.response.use(
  (res) => res,
  async (error) => {
    const originalRequest = error.config;

    if (
      (error.response.status === 403 || error.response.status === 401) &&
      !originalRequest._retry
    ) {
      try {
        if (!getRefreshToken()) {
          window.location.href = `${paths.authentication.login}?destination=${
            window.location.pathname + window.location.search
          }`;
          clearCookies();
          return Promise.reject({
            status: error?.response.status,
            message:
              errorMessage[error?.response.data?.code] ||
              error?.response.data.message ||
              DEFAULT_MESSAGE_ERROR
          });
        }
        originalRequest._retry = true;
        const response = await refreshToken({
          refresh: getRefreshToken()
        });
        const { access, refresh } = response;
        cookie.save(environments.REACT_APP_COOKIE_NAME, access, {
          maxAge: 60 * 60,
          path: "/"
        });
        cookie.save(environments.REACT_APP_COOKIE_REFRESH_NAME, refresh, {
          maxAge: 60 * 60 * 24,
          path: "/"
        });
        axios.defaults.headers.common["Authorization"] = "Bearer " + access;
        return http(originalRequest);
      } catch (e: any) {
        return Promise.reject({
          status: e?.response.status,
          message: e?.response.data.message || DEFAULT_MESSAGE_ERROR
        });
      }
    }
    return Promise.reject({
      status: error?.response.status,
      message:
        errorMessage[error?.response.data.code] ||
        error?.response.data.message ||
        DEFAULT_MESSAGE_ERROR
    });
  }
);
export default http;
