import React from "react";
import { Button, Result } from "antd";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import { TITLE } from "../../utils/title";

export default function FourZeroFour() {
  return (
    <>
      <Helmet>
        <title>{TITLE.FOUR_ZERO_FOUR}</title>
      </Helmet>
      <Result
        status="404"
        title="404"
        subTitle="Sorry, the page you visited does not exist."
        extra={
          <Button type="primary">
            <Link to="/">Back to Home</Link>
          </Button>
        }
      />
    </>
  );
}
