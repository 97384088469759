import http from "../../index";
import uri from "../../uri";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { createReducerThunk, initialState } from "../../../reducers/index";

let reduxActionName = "engineer-mission-info";

async function engineerMissionInfoApi(payload: { imageId: number }) {
  const result = await http.get(uri.engineers["mission-info"](payload.imageId));
  return { ...result.data };
}

export const engineerMissionInfoThunk: any = createAsyncThunk(
  reduxActionName,
  engineerMissionInfoApi
);
export const engineerMissionInfoSlice = createReducerThunk(
  reduxActionName,
  initialState,
  engineerMissionInfoThunk
);
export const engineerMissionInfoReset = engineerMissionInfoSlice.actions.reset;

//-------------------------

reduxActionName = "engineer-mission-info-edit";

async function engineerMissionInfoEditApi(payload: { imageId: number; data: any }) {
  const result = await http.patch(uri.engineers["mission-info"](payload.imageId), payload.data);
  return { ...result.data };
}

export const engineerMissionInfoEditThunk: any = createAsyncThunk(
  reduxActionName,
  engineerMissionInfoEditApi
);
export const engineerMissionInfoEditSlice = createReducerThunk(
  reduxActionName,
  initialState,
  engineerMissionInfoEditThunk
);
export const engineerMissionInfoEditReset = engineerMissionInfoEditSlice.actions.reset;
