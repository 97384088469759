import cookies from "react-cookies";
import { environments } from "../contants";

export const getAccessToken = () => {
  return cookies.load(environments.REACT_APP_COOKIE_NAME)
    ? cookies.load(environments.REACT_APP_COOKIE_NAME)
    : "";
};

export const getRefreshToken = () => {
  return cookies.load(environments.REACT_APP_COOKIE_REFRESH_NAME);
};

export const clearCookies = () => {
  cookies.remove(environments.REACT_APP_COOKIE_NAME, { path: "/" });
  cookies.remove(environments.REACT_APP_COOKIE_REFRESH_NAME, { path: "/" });
};
