import http from "../index";
import uri from "../uri";
import { paths } from "../../../configs/routeConfig";
import { clearCookies } from "../../../utils/cookies";

type loginType = {
  email: string;
  password: string;
  remember: boolean;
};

type refreshType = {
  refresh: string;
};

type changePasswordType = {
  old_password: string;
  new_password1: string;
  new_password2: string;
};

type resetType = {
  email: string;
};

type confirmResetPasswordType = {
  new_password1: string;
  new_password2: string;
  uid: string;
  token: string;
};

export async function loginService(payload: loginType) {
  const result = await http.post(uri.authentication.login, payload);
  return { ...result.data, remember: payload.remember };
}

export async function refreshToken(payload: refreshType) {
  try {
    const result = await http.post(uri.authentication.refresh, payload);
    return result.data;
  } catch (error) {
    window.location.href = paths.authentication.login;
    clearCookies();
  }
}

export async function changePassword(payload: changePasswordType) {
  const result = await http.post(uri.authentication.changePassword, payload);
  return result.data;
}

export async function resetPassword(payload: resetType) {
  const result = await http.post(uri.authentication.resetPassword, payload);
  return result.data;
}

export async function confirmResetPassword(payload: confirmResetPasswordType) {
  const result = await http.post(uri.authentication.confirmResetPassword, payload);
  return result.data;
}
