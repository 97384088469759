import { FC, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { getAccessToken, getRefreshToken } from "../../utils/index";
import { refreshToken } from "../../redux/api/services/authentication";
import { environments } from "../../contants";
import cookie from "react-cookies";

type PropsType = {
  children: any;
};

const Auth: FC<PropsType> = ({ children }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const access = getAccessToken();
  const rfToken = getRefreshToken();

  const handleRefreshToken = async () => {
    const response = await refreshToken({
      refresh: rfToken
    });
    cookie.save(environments.REACT_APP_COOKIE_NAME, response.access, {
      maxAge: 60 * 60,
      path: "/"
    });
    cookie.save(environments.REACT_APP_COOKIE_REFRESH_NAME, response.refresh, {
      maxAge: 60 * 60 * 24,
      path: "/"
    });
  };

  useEffect(() => {
    if (!access && !rfToken) {
      navigate(`/accounts/login?destination=${location.pathname + location.search}`);
    } else if (!access) {
      handleRefreshToken();
    }
  }, [access, rfToken]);

  if (!access && !rfToken) {
    return null;
  }
  return children;
};

export default Auth;
