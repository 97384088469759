import { lazy } from "react";
import DatasetEnlargePhoto from "../containers/DatasetAnalyze/EnlargePhoto";
import FourZeroFour from "../containers/FourZeroFour";
import type { RouteProps } from "../utils/type";

const Homepage = lazy(() => import("../containers/homepage"));
const LoginPage = lazy(() => import("../containers/loginpage"));
const ForgotPassword = lazy(() => import("../containers/forgotPassword"));
const ChangePassword = lazy(() => import("../containers/ChangePassword"));
const DoneResetPassword = lazy(() => import("../containers/doneResetPassword"));
const DatasetReport = lazy(() => import("../containers/datasetReport"));
const DatasetAnalyze = lazy(() => import("../containers/DatasetAnalyze"));
const UploadPage = lazy(() => import("../containers/Upload"));
const DatasetConfigure = lazy(() => import("../containers/datasetConfigure"));
const ConfirmForgotPassword = lazy(() => import("../containers/confirmForgotPassword"));
const GenerateReport = lazy(() => import("../containers/Report"));

export const paths = {
  dataset: {
    configure: (id: number) => `/dataset/configure/${id}`,
    analyze: (id: number) => `/dataset/analyze/${id}`,
    enlargePhoto: (id: number) => `/dataset/enlarge-photo/${id}`,
    report: (imageId: number, queryString: string) => `/dataset/report/${imageId}${queryString}`
  },
  authentication: {
    login: "/accounts/login",
    changePassword: "/accounts/password/change",
    forgotPassword: "/accounts/password/reset"
  }
};

export const routeConfig: RouteProps[] = [
  {
    path: "/*",
    element: FourZeroFour
  },
  {
    path: "/forgot-password/change-password/:uid/:token",
    element: ConfirmForgotPassword,
    noneAuth: true
  },
  {
    path: "/accounts/login",
    element: LoginPage,
    noneAuth: true
  },
  {
    path: "/accounts/password/reset/done",
    element: DoneResetPassword
  },
  {
    path: "/accounts/password/reset",
    element: ForgotPassword,
    noneAuth: true
  },
  {
    path: "/accounts/password/change",
    element: ChangePassword,
    isAuth: true
  },
  {
    path: "/dataset-report",
    element: DatasetReport,
    isAuth: true
  },
  {
    path: "/dataset/configure/:id",
    element: DatasetConfigure,
    isAuth: true
  },
  {
    path: "/dataset/analyze/:id",
    element: DatasetAnalyze,
    isAuth: true
  },
  {
    path: "/dataset/enlarge-photo/:id",
    element: DatasetEnlargePhoto,
    isAuth: true
  },
  {
    path: "/dataset/report/:id",
    element: GenerateReport,
    isAuth: true
  },
  {
    path: "/upload",
    element: UploadPage,
    isAuth: true
  },
  {
    path: "/",
    element: Homepage,
    isAuth: true
  }
];
