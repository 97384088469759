import React, { useEffect, useState } from "react";

export default function EnlargePhoto() {
  const [enlarge, setEnlarge] = useState<any>("");
  useEffect(() => {
    setEnlarge(localStorage.getItem("enlarge"));
  }, [localStorage.getItem("enlarge")]);

  return (
    <div>
      <img src={enlarge} style={{ width: "100%" }} />
    </div>
  );
}
