export const errorMessage: { [key: number]: string } = {
  1000: "Oops! Something went wrong, please try again",
  1001: "Oops! Something went wrong, please try again",
  1009: "Your email address is invalid. Please check again",
  1010: "Please enter all fields correctly",
  1011: "You need to login into the system to use this function.",
  2001: "This e-mail address does not exist.",
  2008: "User account is disabled.",
  3000: "This data set name already exist at this S3 bucket. Please check again.",
  3001: "The file format you uploaded is not correct, please try again",
  4000: "Defect geom can't a empty list. Please check again.",
  4001: "Please enter all required fields",
  4002: "Please enter all required fields",
  4004: "Unknown Action.",
  4005: "Please enter all required fields",
  4006: "The tag does not exist",
  4007: "The defect does not exist",
  5000: "Generate Word Document fail. Please try again later.",
  5001: "Floor Plan already completed or not exists, refresh page to see latest completion status",
  5002: "Email settings doesn't exist",
  8000: "Token is invalid or expired.",
  8001: "Token is invalid or expired."
};
