import { DownOutlined, MenuOutlined, UserOutlined } from "@ant-design/icons";
import { Button, Col, Dropdown, Layout, MenuProps, Modal, Row, Space, Typography } from "antd";
import jwtDecode from "jwt-decode";
import { useMemo, useState } from "react";
import { Link } from "react-router-dom";
import { paths } from "../../configs/routeConfig";
import constants from "../../utils/constants";
import { clearCookies, getAccessToken, getRefreshToken } from "../../utils/cookies";
import { ReactProps } from "../../utils/type";

export default function LayoutComponent({ children }: ReactProps) {
  const [popupConfirmSignOut, setPopupConfirmSignOut] = useState<boolean>(false);
  const handleLogout = () => {
    clearCookies();
    window.location.href = `${paths.authentication.login}?destination=${
      window.location.pathname + window.location.search
    }`;
  };

  const username = useMemo((): string => {
    let result = "";
    if (getAccessToken()) {
      const tokenDecoded: any = jwtDecode(getAccessToken());
      result = tokenDecoded.username;
    } else if (getRefreshToken()) {
      const refreshDecoded: any = jwtDecode(getRefreshToken());
      result = refreshDecoded.username;
    }
    return result || "";
  }, [getAccessToken(), getRefreshToken()]);

  const items: MenuProps["items"] = [
    {
      key: "change-password",
      label: <Link to={paths.authentication.changePassword}>Change Password</Link>
    },
    {
      key: "sign-out",
      label: (
        <span onClick={() => setPopupConfirmSignOut(true)}>
          Sign out as <Typography.Text style={{ color: "blue" }}>@{username}</Typography.Text>
        </span>
      )
    }
  ];

  const items1: MenuProps["items"] = [
    {
      label: <Link to="/upload">Upload</Link>,
      key: "1"
    }, // remember to pass the key prop
    {
      label: <Link to="/dataset-report">Dataset Report</Link>,
      key: "2"
    },
    ...items
  ];

  const menuItems = (label: string, href: string, constant: any, key: number) => (
    <Button style={{ padding: "0 8px" }} type="link" key={key}>
      <Link to={href}>
        <Typography.Text strong style={{ color: "white" }}>
          {label}
        </Typography.Text>
      </Link>
    </Button>
  );

  const mItems = [
    {
      label: "Upload",
      href: "/upload",
      constant: constants.UPLOAD_PAGE_TITLE,
      key: 1
    },
    {
      label: "Dataset Report",
      href: "/dataset-report",
      constant: constants.DATASET_REPORT_PAGE_TITLE,
      key: 2
    }
  ];

  return (
    <Layout className={"layout"}>
      <Layout.Header className="header">
        <Row justify={"space-between"} align={"middle"}>
          <Col>
            <Space align="start" wrap>
              <Link to={"/"}>
                <img src="/h3zoom_logo.png" alt="logo" />
              </Link>
              <Link className="hide-sm" to={"/"}>
                <h3 className={"header_link"}>Interior Inspector</h3>
              </Link>
            </Space>
          </Col>

          {username && (
            <>
              <Col className="hide-sm">
                <Space>
                  {mItems.map((mi: any) => menuItems(mi.label, mi.href, mi.constant, mi.key))}
                  <Dropdown menu={{ items }}>
                    <Typography.Text style={{ color: "white" }} strong>
                      @{username.substring(0, 3) || <UserOutlined />} <DownOutlined />
                    </Typography.Text>
                  </Dropdown>
                </Space>
              </Col>
              <Col className="show-sm">
                <Dropdown menu={{ items: items1 }}>
                  <Button ghost icon={<MenuOutlined />} />
                </Dropdown>
              </Col>
            </>
          )}
        </Row>
      </Layout.Header>
      <Layout.Content className={"body"}>
        <div className={"inner"}>{children}</div>
      </Layout.Content>
      <Modal
        footer={false}
        maskClosable={false}
        open={popupConfirmSignOut}
        onCancel={() => setPopupConfirmSignOut(false)}
      >
        <Space direction="vertical">
          <Typography.Title level={4} style={{ marginBottom: 0 }}>
            Sign out
          </Typography.Title>
          <Typography.Text>Are you sure want to sign out?</Typography.Text>
          <Button type="primary" onClick={handleLogout}>
            Sign Out
          </Button>
        </Space>
      </Modal>
    </Layout>
  );
}
