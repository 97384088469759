import { FC, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { getAccessToken, getRefreshToken } from "../../utils/index";

type PropsType = {
  children: any;
};

const NoneAuthWrapper: FC<PropsType> = ({ children }) => {
  const navigate = useNavigate();
  const access = getAccessToken();
  const rfToken = getRefreshToken();

  useEffect(() => {
    if (access || rfToken) {
      navigate("/");
    }
  }, [access, rfToken]);

  return children;
};

export default NoneAuthWrapper;
