import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { ReduxState } from "../../utils/type";

export const initialState: ReduxState<any> = {
  loading: false,
  data: [],
  error: "",
  status: false
};

export const createActionThunk = (name: string, cb: any): any => {
  return createAsyncThunk(name, cb);
};

export const createReducerThunk = (name: string, _state: any, thunk: any) => {
  return createSlice({
    name,
    initialState: _state,
    reducers: {
      reset: (state, action: { payload: Array<"loading" | "error" | "data" | "status"> }) => {
        const temp: any = initialState;
        action.payload.forEach((x: string) => {
          state[x] = temp[x];
        });
      }
    },
    extraReducers: (builder: any) => {
      builder.addCase(thunk.pending, (state: any) => {
        state.loading = true;
      });
      builder.addCase(thunk.fulfilled, (state: any, action: any) => {
        state.error = initialState.error;
        state.data = action.payload;
        state.loading = false;
        state.status = true;
      });
      builder.addCase(thunk.rejected, (state: any, action: any) => {
        state.error = action.error.message || "error";
        state.data = initialState.data;
        state.loading = false;
        state.status = false;
      });
    }
  });
};
