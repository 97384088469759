
import http from "../../index";
import uri from "../../uri";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { createReducerThunk, initialState } from "../../../reducers/index";

let reduxActionsName = "engineer-defects";

async function defectsApi(payload: { reviewId: number }) {
  const result = await http.get(uri.engineers.defects(payload.reviewId));
  return result.data;
}

export const engineerDefectsThunk: any = createAsyncThunk(reduxActionsName, defectsApi);
export const engineerDefectsSlice = createReducerThunk(reduxActionsName, initialState, engineerDefectsThunk);

// -------------------------

reduxActionsName = "engineer-defect-create";

async function defectCreateApi(payload: { data: any }) {
  const result = await http.post(uri.engineers["defect-create"](), payload.data);
  return result.data;
}

export const engineerDefectCreateThunk: any = createAsyncThunk(reduxActionsName, defectCreateApi);
export const engineerDefectCreateSlice = createReducerThunk(reduxActionsName, initialState, engineerDefectCreateThunk);
export const engineerDefectCreateReset = engineerDefectCreateSlice.actions.reset;

// -------------------------

reduxActionsName = "engineer-defect-update";

async function defectUpdateApi(payload: { reviewSubId: number, data: any }) {
  const result = await http.patch(uri.engineers["defect-update"](payload.reviewSubId), payload.data);
  return result.data;
}

export const engineerDefectUpdateThunk: any = createAsyncThunk(reduxActionsName, defectUpdateApi);
export const engineerDefectUpdateSlice = createReducerThunk(reduxActionsName, initialState, engineerDefectUpdateThunk);
export const engineerDefectUpdateReset = engineerDefectUpdateSlice.actions.reset;

// -------------------------

reduxActionsName = "engineer-defect-delete";

async function defectDeleteApi(payload: { reviewSubId: number }) {
  const result = await http.delete(uri.engineers["defect-update"](payload.reviewSubId));
  return result.data;
}

export const engineerDefectDeleteThunk: any = createAsyncThunk(reduxActionsName, defectDeleteApi);
export const engineerDefectDeleteSlice = createReducerThunk(reduxActionsName, initialState, engineerDefectDeleteThunk);
export const engineerDefectDeleteReset = engineerDefectDeleteSlice.actions.reset;

// -------------------------

reduxActionsName = "engineer-defect-create-nen";

async function defectCreateNENApi(payload: { data: any }) {
  const result = await http.post(uri.engineers["defect-create-nen"](), payload.data);
  return result.data;
}

export const engineerDefectCreateNENThunk: any = createAsyncThunk(reduxActionsName, defectCreateNENApi);
export const engineerDefectCreateNENSlice = createReducerThunk(reduxActionsName, initialState, engineerDefectCreateNENThunk);
export const engineerDefectCreateNENReset = engineerDefectCreateNENSlice.actions.reset;

// -------------------------

reduxActionsName = "engineer-defect-update-nen";

async function defectUpdateNENApi(payload: { reviewSubId: number, data: any }) {
  const result = await http.patch(uri.engineers["defect-update-nen"](payload.reviewSubId), payload.data);
  return result.data;
}

export const engineerDefectUpdateNENThunk: any = createAsyncThunk(reduxActionsName, defectUpdateNENApi);
export const engineerDefectUpdateNENSlice = createReducerThunk(reduxActionsName, initialState, engineerDefectUpdateNENThunk);
export const engineerDefectUpdateNENReset = engineerDefectUpdateNENSlice.actions.reset;