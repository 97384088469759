import { createBrowserRouter } from "react-router-dom";
import Layout from "./components/layout";
import AuthWrapper from "./components/layout/auth";
import NoneAuthWrapper from "./components/layout/NoneAuthWrapper";
import { routeConfig } from "./configs/routeConfig";
import { ReactProps, RouteProps } from "./utils/type";

function LayoutComponent({ children }: ReactProps) {
  return <Layout>{children}</Layout>;
}

const routes: RouteProps[] = routeConfig.map((x: RouteProps) => {
  let element = (
    <LayoutComponent>
      <x.element />
    </LayoutComponent>
  );
  if (x.isAuth) {
    element = (
      <AuthWrapper>
        <LayoutComponent>
          <x.element />
        </LayoutComponent>
      </AuthWrapper>
    );
  }
  if (x.noneAuth) {
    element = (
      <NoneAuthWrapper>
        <LayoutComponent>
          <x.element />
        </LayoutComponent>
      </NoneAuthWrapper>
    );
  }
  return {
    ...x,
    element
  };
});

export const routers = createBrowserRouter(routes);
