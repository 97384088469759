import http from "../../index";
import uri from "../../uri";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { createReducerThunk, initialState } from "../../../reducers/index";

const reduxActionImageByImageId = "image-by-image-id";
export const getImageByImageid = async (payload: { imageId: number }) => {
  const response = await http.get(uri.report.imageByImageId(payload.imageId));
  return response.data;
};

export const imageByImageIdThunk: any = createAsyncThunk(
  reduxActionImageByImageId,
  getImageByImageid
);
export const imageByImageIdSlice = createReducerThunk(
  reduxActionImageByImageId,
  initialState,
  imageByImageIdThunk
);
