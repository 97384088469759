import { configureStore } from "@reduxjs/toolkit";
import counterReducer from "./reducers/counter";
import datasetReducer from "./reducers/dataset";
import engineersReducer from "./reducers/engineers";
import authenticationReducer from "./reducers/authentication";
import reportReducer from "./reducers/report";

export const store = configureStore({
  reducer: {
    counter: counterReducer,
    ...datasetReducer,
    ...engineersReducer,
    ...reportReducer,
    authentication: authenticationReducer
  }
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
