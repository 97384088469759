import http from "../../index";
import uri from "../../uri";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { createReducerThunk, initialState } from "../../../reducers/index";

const reduxActionNameSectionList = "dataset-section-list";
async function sectionListApi() {
  const result = await http.get(uri.dataset.section.list);
  return result.data;
}
export const datasetSectionListThunk: any = createAsyncThunk(
  reduxActionNameSectionList,
  sectionListApi
);
export const datasetSectionListSlice = createReducerThunk(
  reduxActionNameSectionList,
  initialState,
  datasetSectionListThunk
);
export const datasetSectionReset = datasetSectionListSlice.actions.reset;

// -------------------------------------------------------------

type updateDatasetType = {
  imageId: number;
  sectionId: number;
};
const reduxActionNameSectionUpdate = "dataset-section-update";
async function sectionUpdateApi(payload: updateDatasetType) {
  const result = await http.get(uri.dataset.section.update(payload.imageId, payload.sectionId));
  return result.data;
}
export const datasetSectionUpdateThunk: any = createAsyncThunk(
  reduxActionNameSectionUpdate,
  sectionUpdateApi
);
export const datasetSectionUpdateSlice = createReducerThunk(
  reduxActionNameSectionUpdate,
  initialState,
  datasetSectionUpdateThunk
);
