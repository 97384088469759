
import http from "../../index";
import uri from "../../uri";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { createReducerThunk, initialState } from "../../../reducers/index";

const reduxActionNameConfigureList = "dataset-configure-list";
async function configureListApi(payload: {datasetId: number}) {
  const result = await http.get(uri.dataset.configure.list(+payload.datasetId));
  return result.data;
}
export const datasetConfigureListThunk: any = createAsyncThunk(reduxActionNameConfigureList, configureListApi);
export const datasetConfigureListSlice = createReducerThunk(reduxActionNameConfigureList, initialState, datasetConfigureListThunk);

// // -------------------------------------------------------------

const reduxActionNameConfigureHDB = "dataset-configure-hdb";
async function configureListHDBApi() {
  const result = await http.get(uri.dataset.configure.listHDB);
  return result.data;
}
export const datasetConfigureHDBThunk: any = createAsyncThunk(reduxActionNameConfigureHDB, configureListHDBApi);
export const datasetConfigureHDBSlice = createReducerThunk(reduxActionNameConfigureHDB, initialState, datasetConfigureHDBThunk);
export const datasetConfigureReset = datasetConfigureHDBSlice.actions.reset;
// // -------------------------------------------------------------

type dataSetConfigureUpdateType = {
  data: {
    ["sub_components"]: {
      id: number;
      ["material_ids"]: number[]
    }
  }[],
  datasetId: number;
};
const reduxActionNameConfigureUpdate = "dataset-configure-update";
async function configureUpdateApi(payload: dataSetConfigureUpdateType) {
  const result = await http.patch(uri.dataset.configure.update(+payload.datasetId), payload.data);
  return result.data;
}
export const datasetConfigureUpdateThunk: any = createAsyncThunk(reduxActionNameConfigureUpdate, configureUpdateApi);
export const datasetConfigureUpdateSlice = createReducerThunk(reduxActionNameConfigureUpdate, initialState, datasetConfigureUpdateThunk);
export const datasetConfigureUpdateReset = datasetConfigureUpdateSlice.actions.reset;
