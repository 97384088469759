import http from "../../index";
import uri from "../../uri";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { createReducerThunk, initialState } from "../../../reducers/index";

type listDatasetType = {
  page: number;
  limit: number;
  search: string;
};

const reduxActionName = "dataset-list";

async function api(params: listDatasetType) {
  const result = await http.get(uri.dataset.list, { params });
  return { ...result.data };
}

export const datasetListThunk: any = createAsyncThunk(reduxActionName, api);
export const datasetListSlice = createReducerThunk(reduxActionName, initialState, datasetListThunk);
export const datasetListReset = datasetListSlice.actions.reset;
