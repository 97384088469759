
import http from "../../index";
import uri from "../../uri";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { createReducerThunk, initialState } from "../../../reducers/index";

const reduxActionOptionsName = "engineer-options";
async function optionsApi(payload: { imageId: number }) {
  const result = await http.get(uri.engineers.options(payload.imageId));
  return result.data;
}
export const engineerOptionsThunk: any = createAsyncThunk(reduxActionOptionsName, optionsApi);
export const engineerOptionsSlice = createReducerThunk(reduxActionOptionsName, initialState, engineerOptionsThunk);

// ------------------------------

const reduxActionExtentName = "engineer-extent";
async function extentApi() {
  const result = await http.get(uri.engineers.extent());
  return result.data;
}
export const engineerExtentThunk: any = createAsyncThunk(reduxActionExtentName, extentApi);
export const engineerExtentSlice = createReducerThunk(reduxActionExtentName, initialState, engineerExtentThunk);

// ------------------------------

const reduxActionIntensityName = "engineer-intensity";
async function intensityApi() {
  const result = await http.get(uri.engineers.intensity());
  return result.data;
}
export const engineerIntensityThunk: any = createAsyncThunk(reduxActionIntensityName, intensityApi);
export const engineerIntensitySlice = createReducerThunk(reduxActionIntensityName, initialState, engineerIntensityThunk);