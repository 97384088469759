import http from "../../index";
import uri from "../../uri";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { createReducerThunk, initialState } from "../../../reducers/index";

export type UpdateDefectPayloadType = {
  id: number;
  review?: number;
  ordering?: number;
  component?: number;
  component_category?: number;
  subcomponent?: number;
  material?: number;
  description?: number;
  comment?: string;
  resolution?: string;
  unit_no?: string;
  level?: string;
  photos?: string;
  files?: {
    review_image_id: number;
    file_name: string;
    bs64: string;
    action: string;
    ordering: string;
    image_type: string;
  };
  is_completed?: boolean;
}[];

const reduxActionUpdateReportDefect = "update-report-defect";
export const updateDefectApi = async (payload: UpdateDefectPayloadType) => {
  const response = await http.patch(uri.report.updateDefect, payload);
  return response.data;
};

export const updateReportDefectThunk: any = createAsyncThunk(
  reduxActionUpdateReportDefect,
  updateDefectApi
);
export const updateReportDefectSlice = createReducerThunk(
  reduxActionUpdateReportDefect,
  initialState,
  updateReportDefectThunk
);
export const updateReportDefectReset = updateReportDefectSlice.actions.reset;

type ParamsHistories = {
  page: number;
  limit: number;
  dataset_image_id: number;
};

const reduxActionGetHitories = "get-histories";
export const getHistoriesByImageId = async (params: ParamsHistories) => {
  const response = await http.get(uri.report.histories, {
    params
  });
  return response.data;
};

export const getHistoriesThunk: any = createAsyncThunk(
  reduxActionGetHitories,
  getHistoriesByImageId
);
export const getHistoriesSlice = createReducerThunk(
  reduxActionGetHitories,
  initialState,
  getHistoriesThunk
);

const reduxActionValidated = "validated-defect";
export const validateDefectApi = async ({
  reviewId,
  reviewSubId,
  isCompleted
}: {
  reviewId: number;
  reviewSubId: number;
  isCompleted: boolean;
}) => {
  const response = await http.post(uri.report.validateDefect(reviewId, reviewSubId), {
    is_completed: isCompleted
  });
  return response.data;
};

export const validatedDefectThunk: any = createAsyncThunk(reduxActionValidated, validateDefectApi);
export const validatedDefectSlice = createReducerThunk(
  reduxActionValidated,
  initialState,
  validatedDefectThunk
);

const reduxFloorImageCompletedName = "floor-image-completed";
export const setFloorImageCompletedApi = async (payload: { imageId: number }) => {
  const response = await http.get(uri.dataset.report.setFloorImageCompleted(payload.imageId));
  return response.data;
};

export const floorImageCompletedThunk: any = createAsyncThunk(
  reduxFloorImageCompletedName,
  setFloorImageCompletedApi
);
export const floorImageCompletedSlice = createReducerThunk(
  reduxFloorImageCompletedName,
  initialState,
  floorImageCompletedThunk
);
export const floorImageCompletedReset = floorImageCompletedSlice.actions.reset;
