import http from "../../index";
import uri from "../../uri";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { createReducerThunk, initialState } from "../../../reducers/index";

const reduxActionName = "engineer-list";

async function api(payload: { imageId: number }) {
  const result = await http.get(uri.engineers.list(payload.imageId));
  return { ...result.data };
}

export const engineerListThunk: any = createAsyncThunk(reduxActionName, api);
export const engineerListSlice = createReducerThunk(
  reduxActionName,
  initialState,
  engineerListThunk
);
export const engineerListReset = engineerListSlice.actions.reset;

const reduxActionDefectsByImageId = "defects-by-image-id";
export const getDefectsByImageId = async (payload: { imageId: number; params: any }) => {
  const response = await http.get(uri.engineers.defectsByImageId(payload.imageId), {
    params: payload.params
  });
  return response.data;
};
export const datasetDefectsThunk: any = createAsyncThunk(
  reduxActionDefectsByImageId,
  getDefectsByImageId
);
export const datasetDefectsSlice = createReducerThunk(
  reduxActionDefectsByImageId,
  initialState,
  datasetDefectsThunk
);

const reduxActionDatasetReportSmrt = "dataset-report-smrt";
export const getSmrtReport = async (payload: { datasetId: number }) => {
  const response = await http.get(uri.engineers.smrtReport(payload.datasetId));
  return response.data;
};
export const engineerSmrtReportThunk: any = createAsyncThunk(
  reduxActionDatasetReportSmrt,
  getSmrtReport
);
export const engineerSmrtReportSlice = createReducerThunk(
  reduxActionDatasetReportSmrt,
  initialState,
  engineerSmrtReportThunk
);

const reduxActionReportByBuilding = "report-by-building";
export const getReportByBuilding = async (payload: { datasetId: number }) => {
  const response = await http.get(uri.engineers.reportByBuilding(payload.datasetId));
  return response.data;
};

export const getReportByBuildingThunk: any = createAsyncThunk(
  reduxActionReportByBuilding,
  getReportByBuilding
);
export const getReportByBuildingSlice = createReducerThunk(
  reduxActionReportByBuilding,
  initialState,
  getReportByBuildingThunk
);

// -----------------------------
export const engineerPFILocationListThunk: any = createAsyncThunk(
  "engineer-pfi-location-list",
  async () => {
    const response = await http.get(uri.engineers.location_list);
    return response.data;
  }
);
export const engineerPFILocationListSlice = createReducerThunk(
  reduxActionName,
  initialState,
  engineerPFILocationListThunk
);
